import React from "react";
import styled from "styled-components";
import * as XLSX from "xlsx";

const ExportButtonStyle = styled.div`
  cursor: pointer;
  width: ${(p) => p.width};
  height: 32px;
  border-radius: 8px;
  border: solid 1px var(--deemGray);
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  color: var(--textDarkGray);
  ${(p) => p.cssStyle}
`;
export default function ExportExcelButton({
  width = "160px",
  data,
  cssStyle,
  title = "excel.xlsx",
}) {
  const downloadExcel = (arr) => {
    const workbook = XLSX.utils.book_new();
    const sheet = XLSX.utils.json_to_sheet(arr);
    XLSX.utils.book_append_sheet(workbook, sheet, "my_sheet");
    XLSX.writeFile(workbook, title);
  };

  return (
    <ExportButtonStyle
      width={width}
      onClick={() => downloadExcel(data || [])}
      cssStyle={cssStyle}
    >
      <div className="material-icons">download</div>
      Download Excel
    </ExportButtonStyle>
  );
}
